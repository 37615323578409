.modalProfilo {
  .MuiDialog-paper {
    width: 40%;
    max-width: 100%;
    padding-bottom: 24px;
    .MuiDialogTitle-root {
      display: flex;
      justify-content: flex-end;
      padding-right: 12px;
      h2 {
        display: none;
      }
      .MuiIconButton-root {
        svg {
          fill: black;
        }
        &:hover {
          background-color: black;
          svg {
            fill: white;
          }
        }
      }
    }
    .MuiDialogContent-root {
      .MuiTypography-root {
        text-align: center;
        font-size: 24px;
      }
    }
    .MuiDialogActions-root {
      justify-content: center;
      .MuiButton-root {
        margin: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 899px) {
  .modalProfilo {
    .MuiDialog-paper {
      width: 100%;
      .MuiDialogContent-root {
        .MuiTypography-root {
          font-size: 20px;
        }
      }
      .MuiDialogActions-root {
        .MuiButton-root {
          font-size: 16px;
        }
      }
    }
  }
}
