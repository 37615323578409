.dropdown {
  margin-top: 10px;
  width: 210px;
  z-index: 7;
  position: absolute;
  display: block;
  @media screen and (max-width: 1518px) {
    margin-top: 20px;
  }
  .buttonApplica {
    box-shadow: 0px 3px 6px #000000e0;
    border-radius: 15px;
    color: white;
    background-color: #1f7a1f;
    margin-bottom: 20px;
    width: 70px;
    height: 30px;
    font-weight: bold;
    text-transform: none;
    font-size: 15px !important;
    &:hover {
      color: white;
      background-color: #1f7a1f;
      text-decoration: underline;
    }
  }

  .buttonPulisci {
    box-shadow: 0px 3px 6px #000000e0;
    margin-right: 10px;
    border-radius: 15px;
    color: black;
    background-color: white;
    width: 70px;
    height: 30px;
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: none;
    font-size: 15px !important;
    &:hover {
      color: black;
      background-color: white;
      text-decoration: underline;
    }
  }
}
