.istituto {
  .tabs {
    .Mui-selected {
      color: #fff;
    }
  }
  .textTab {
    font-size: 20px;
    text-transform: none;
    border-radius: 10px;
    color: black;
    margin: 1px;
    &:hover {
      text-decoration: underline;
    }
    @media screen and (max-width: 900px) {
      font-size: 16px;
    }
  }
  .tabIstituto {
    .textTitleTabIstituto {
      font-size: 18px;
      font-weight: bold;
    }

    .titleSezioneIstituto {
      margin-top: 50px;
      font-size: 27px;
      font-weight: bold;
    }
    .divider {
      border: 1px solid;
      margin-top: 20px;
      margin-bottom: -20px;
      width: 85%;
      margin-left: 5%;
      height: 0px;
    }
    .titleCampi {
      margin-top: 50px;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .MuiTableCell-root {
    padding: 16px 4px;
    .MuiGrid-root {
      line-height: 1;
    }
  }
  .tabMembriTeam {
    .titleCampiMembriTeam {
      font-size: 18px;
      font-weight: bold;
    }

    .avatar {
      width: 140px;
      height: 140px;
      border-style: solid;
      background-color: white;
    }
  }
}
