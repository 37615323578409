@import "./buttons";
@import "./empty";
@import "./gatewayError";
@import "./loadingSpinner";
@import "./loggedMenu";
@import "./mobileLoggedMenu.scss";
@import "./notification";
@import "./pageNotFound";
@import "./privacyError";
@import "./sessionCountdown";
@import "./header";
@import "./dropDown";

.MuiAutocomplete-inputRoot {
  padding-right: 35px !important;
}

.MuiInputBase-root.Mui-error {
  fieldset {
    border-width: 2px;
  }
}

.MuiInputBase-root {
  box-shadow: $buttonBoxShadow;
}
