.privacyError {
  .MuiTypography-root {
    font-size: 24px;
  }
  .MuiBackdrop-root {
    background-color: $backgroundDialog;
  }
  .MuiDialog-paper {
    max-height: 100%;
    max-width: 100%;
    .MuiDialogContent-root {
      display: grid;
      justify-content: center;
      align-content: center;
      svg {
        color: $viola;
        font-size: 150px;
        margin: auto;
        margin-bottom: 25px;
      }
      .MuiTypography-h3 {
        font-weight: 600;
        margin-bottom: 25px;
      }
      .MuiTypography-root {
        text-align: center;
      }
    }
    .MuiCard-root {
      width: 100%;
      height: 100%;
      box-shadow: none;
      .MuiCardContent-root {
        width: 100%;
        height: 100%;
        iframe {
          width: calc(100% - 32px);
          height: 100%;
          border: none;
        }
      }
    }
    .MuiDialogActions-root {
      padding: 32px;
    }
  }
}
