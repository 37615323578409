@import "./variables.scss";
@import "./pages/";
@import "./pages/";
@import "./shared/";
@import "./pages/";

html,
body,
#root,
#mainContent {
  height: 100%;
  .MuiTypography-root {
    font-size: 17px;
  }
  .textTitle {
    cursor: default;
    font-size: 18px;
    font-weight: bold !important;
  }
  .fieldLabel {
    font-weight: 600;
    color: black;
    font-size: 1rem;
  }
  .arrowCircleIcon {
    width: 40px;
    height: 30px;
    margin-top: 10px;
  }
  .tabOvali {
    border: none;
    text-align: center;
    width: 100%;
    height: 30px;
    border-radius: 90px;
    opacity: 1;
    float: left;
    font-size: 20px;
    text-transform: none;
    font-weight: bold;
    box-shadow: 0px 3px 6px #000000;
    @media screen and (max-width: 900px) {
      font-size: 16px;
    }
  }

  .fontBold {
    font-weight: bold;
    font-size: 18px;
  }
  .titlePage {
    font-size: 32px;
    font-weight: bold;
    padding-top: 10px;
    @media screen and (max-width: 360px) {
      font-size: 16px !important;
    }
  }
  .textTitleModal {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    color: black;
    opacity: 1;
  }
  .tabsAttivitaCurricolari {
    height: 60px;
    border: 1px;
    box-shadow: 0px 3px 6px #000000c7;
    &button {
      margin-top: -10px;
      border-radius: 2;
      box-shadow: 0px 3px 6px #000000c7;
      color: black;
    }
    &button.Mui-selected {
      color: #000;
    }
  }
  .tabAttivitaCurricolari {
    height: 60px;
    text-transform: none;
    color: #000;
    box-shadow: 0px 3px 6px #000000c7;
    font-weight: bold;
    font-size: 18px;
    @media screen and (max-width: 900px) {
      font-size: 16px;
    }
  }
  .center {
    justify-content: center;
    display: flex;
  }
  .link {
    color: black;
    text-decoration: none;
    @media screen and (max-width: 900px) {
      line-height: 1;
    }
    font-size: 16px !important;
    // font-family: Arial, Helvetica, sans-serif;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    &:hover {
      text-decoration: underline;
    }
  }

  a,
  button,
  input,
  textarea,
  .MuiButton-root,
  .MuiButtonBase-root,
  .MuiIconButton-root,
  .MuiSelect-select {
    &:focus {
      border: 2px solid black !important;
    }
  }

  .MuiInputBase-root.Mui-error {
    fieldset {
      border-width: 2px;
    }
  }

  .cardfiltroFinalita {
    width: 100%;
    height: 41px;
    box-shadow: 0px 3px 6px #000000c7;
  }
  .placeholderFiltroFinalita {
    margin-right: 85%;
    margin-left: 10px;
    font-style: italic;
    color: "#595959";
    font-size: 18px;
    opacity: 0.5;
  }

  .iconFiltroFinalita {
    margin-top: -3px;
    color: black;
    width: 30px;
    height: 30px;
  }
  .MuiFormHelperText-root {
    margin-bottom: -24px;
  }
  // Fix accessibilità per select disabilitata
  .MuiFormControl-root {
    .MuiInputLabel-root.Mui-disabled {
      color: #5c5c5c;
    }
    .customSelect.Mui-disabled {
      div {
        color: #5c5c5c;
        -webkit-text-fill-color: #5c5c5c;
      }
      .MuiSelect-select {
        background-color: #e3e3e3;
      }
    }
    .MuiSvgIcon-root.Mui-disabled {
      fill: #5c5c5c;
    }
  }

  .MuiAutocomplete-root {
    .Mui-disabled {
      -webkit-text-fill-color: #5c5c5c;
      background-color: #e3e3e3;
      .MuiSvgIcon-root {
        fill: #5c5c5c;
      }
    }
  }
  .rotate {
    transform: rotate(180deg);
    color: black;
  }
  .rotate2 {
    transform: rotate(0deg);
    color: black;
  }

  .tableResponsive {
    display: none;
    @media screen and (max-width: 1024px) {
      display: block;
    }
    .titleTableResponsive {
      font-weight: bolder;
      margin-top: 10px;
      margin-left: 10px;
      color: black;
    }
    .campiTableResponsive {
      margin-top: 10px;
      margin-left: 10px;
      font-weight: bolder;
    }
    .borderTableResponsive {
      border: solid;
      border-width: 1px;
    }
  }
  .header {
    .buttonHome {
      .MuiIconButton-root {
        &:focus {
          border: 2px solid $verde !important;
        }
      }
    }
  }

}

.dato {
  cursor: default;
  margin-bottom: 30px;
}

#mainContent {
  padding: 0 16px;
}
