$verde: #105b70;
$verdeHover: #98bcbd;
$viola: #85133e;
$violaHover: #dea4c0;

$verdeChiaro: #98bcbd;
$verdeChiaroHover: #105b70;

$viola: #85133e;
$violaHover: #dea4c0;

$violaChiaro: #dea4c0;
$violaChiaroHover: #85133e;

$verdeChiaro: #98bcbd;
$verdeChiaroHover: #105b70;

$viola: #85133e;
$violaHover: #dea4c0;

$violaChiaro: #dea4c0;
$violaChiaroHover: #85133e;

$buttonBoxShadow: 0px 3px 6px #000000e0;

$backgroundDialog: #000000d9;

$giallo: #fabe10;
$vinaccia: #8e2155;
