html,
body,
#root,
#mainContent {
  .MuiButton-root.Mui-disabled {
    color: black;
    background-color: #c2c2c2;
  }
  .buttonVerde,
  .buttonVerdeSecondario,
  .buttonVerdeChiaro,
  .buttonViola,
  .buttonViolaSecondario,
  .buttonViolaChiaro {
    padding: 6px 16px;
    margin: auto;
    text-transform: none;
    box-shadow: $buttonBoxShadow;
    font-size: 20px !important;
    border-radius: 10px;
    &:hover {
      text-decoration: underline;
    }
    @media screen and (max-width: 460px) {
      font-size: 16px !important;
      padding: 6px 6px;
    }
  }

  .buttonVerde:not(.Mui-disabled),
  .buttonViola:not(.Mui-disabled) {
    color: white;
    &:hover {
      color: black;
    }
  }

  .buttonVerdeSecondario:not(.Mui-disabled),
  .buttonViolaSecondario:not(.Mui-disabled) {
    background-color: white;
    color: black;
    &:hover {
      color: white;
    }
  }

  .buttonVerdeChiaro:not(.Mui-disabled),
  .buttonViolaChiaro:not(.Mui-disabled) {
    color: black;
    &:hover {
      color: white;
    }
  }

  .buttonVerde:not(.Mui-disabled) {
    border: 2px solid $verde;
    background-color: $verde;
    &:hover {
      background-color: $verdeHover;
    }
  }

  .buttonVerdeSecondario:not(.Mui-disabled) {
    border: 2px solid $verde;
    &:hover {
      background-color: $verde;
    }
  }

  .buttonVerdeChiaro:not(.Mui-disabled) {
    background-color: $verdeChiaro;
    &:hover {
      background-color: $verdeChiaroHover;
    }
  }

  .buttonViola:not(.Mui-disabled) {
    border: 2px solid $viola;
    background-color: $viola;
    &:hover {
      background-color: $violaHover;
    }
  }

  .buttonViolaSecondario:not(.Mui-disabled) {
    border: 2px solid $viola;
    &:hover {
      background-color: $viola;
    }
  }

  .buttonViolaChiaro:not(.Mui-disabled) {
    background-color: $violaChiaro;
    &:hover {
      background-color: $violaChiaroHover;
    }
  }
}
