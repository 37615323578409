.header {
  padding-top: 10px;
  .MuiGrid-container {
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    .leftElement {
      display: flex;
      align-items: center;
      .backButton {
        margin-right: 15px;
        width: 64px;
        height: 64px;
        &:hover {
          background-color: transparent;
        }
      }
      .customIcon {
        width: 48px;
        height: 48px;
      }
      .titlePage {
        margin-left: 25px;
        @media (max-width: 540px) {
          font-size: 20px !important;
        }
      }
    }
    .buttonHome {
      display: grid;
      justify-content: flex-end;
      .MuiIconButton-root {
        width: 64px;
        height: 64px;
        @media screen and (max-width: 360px) {
          width: 44px;
          height: 44px;
        }
        border: 1px solid black;
        background-color: white;
        svg {
          width: 36px;
          fill: black;
        }
      }
      .MuiTypography-root {
        text-align: center;
      }
      &:hover {
        .MuiIconButton-root {
          background-color: black;
          svg {
            fill: white;
          }
        }
        .MuiTypography-root {
          text-decoration: underline !important;
        }
      }
    }
  }
  .MuiDivider-root {
    border-width: 3px;
    border-style: solid;
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 900px) {
  .header {
    .MuiGrid-container {
      .leftElement {
        .backButton {
          margin-right: -10px;
        }
      }
    }
  }
}
