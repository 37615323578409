.loadingSpinner {
  z-index: 2000;
  .MuiBox-root {
    display: flex;
    justify-content: center;
    height: 100%;
    svg {
      width: 150px;
      animation: spinningAnimation 1.4s linear infinite;
    }
  }
}

@keyframes spinningAnimation {
  100% {
    transform: rotate(360deg);
  }
}
