.inserimentoProgetto {
  .dividerHeaderProgetti {
    border: 3px solid;
    border-color: #fabe10;
    width: 100%;
    opacity: 1;
    margin-bottom: 30px;
  }

  .tabSezioni {
    border: none;
    text-align: center;
    color: black;
    width: 100%;
    height: 40px;
    border-radius: 90px;
    opacity: 1;
    float: left;
    text-transform: none;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0px 3px 6px #000;
    @media screen and (max-width: 1200px) {
      font-size: 14px;
    }
  }

  .arrowCircleIconTab {
    width: 100%;
    height: 40px;
    margin-top: 6px;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .infoCampiObbligatori {
    font-size: 16px;
    color: #707070;
    padding-left: 35px;
    display: flex;
    justify-content: flex-end;
  }

  .linearProgress {
    z-index: -1;
    height: 30px;
    background-color: white;
    box-shadow: 0px 3px 6px #000000e0;
  }

  .positionLinearP {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .textLinearProgress {
    color: black;
    font-size: 26px;
    font-weight: bold;
    width: 90px;
    position: absolute;
    left: calc(calc(100% - 0px) / 2);
    top: 2px;
  }
  .buttonSalvaEsci {
    margin: 20px;
    box-shadow: 0px 3px 6px #000000e0;
    border-radius: 10px;
    color: black;
    background-color: #8e2155;
    width: 200px;
    height: 40px;
    &:hover {
      background-color: #8e2155;
      color: white;
      text-decoration: underline;
    }
  }
  .buttonSalvaAvanti {
    margin: 20px;
    box-shadow: 0px 3px 6px #000000e0;
    border-radius: 10px;
    background-color: #fabe10;
    width: 200px;
    height: 40px;
    .MuiTypography-root {
      color: black;
    }
    &:hover {
      background-color: #fabe10;
      color: black;
      text-decoration: underline;
    }
  }
  .textButton {
    font-weight: bold;
    text-transform: none;
    font-size: 20px;
    color: white;
    @media screen and (max-width: 460px) {
      font-size: 16px !important;
    }
  }

  .textDialog {
    font-size: 18px;
  }

  .buttonDialog {
    margin: 20px;
    box-shadow: 0px 3px 6px #000000e0;
    border-radius: 10px;
    color: black;
    background-color: #8e2155;
    width: 150px;
    height: 40px;
    &:hover {
      color: white;
      background-color: #8e2155;
      text-decoration: underline;
    }
  }

  .textButton {
    font-weight: bold;
    text-transform: none;
    font-size: 16px;
    color: white;
  }

  .modaleHome {
    z-index: 3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 350px;
    background-color: #fff;
    border: 1px solid #707070;
    border-radius: 40px;
    @media screen and (max-width: 640px) {
      width: 400px;
    }
    @media screen and (max-width: 410px) {
      width: 300px;
      height: 450px;
    }

    .closeIcon {
      float: right;
      margin: 15px;
      color: black;
    }

    .textModaleHome {
      text-transform: none;
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .textButtonModaleHome {
      font-weight: bold;
      text-transform: none;
    }

    .buttonEsci {
      width: 120px;
      margin: 20px;
      box-shadow: 0px 3px 6px #000000e0;
      border-radius: 10px;
      color: black;
      border: solid;
      border-color: #fabe10;
      background-color: #fff;
      &:hover {
        color: black;
        background-color: #fff;
        text-decoration: underline;
      }
    }

    .buttonAnnulla {
      margin: 20px;
      box-shadow: 0px 3px 6px #000000e0;
      border-radius: 10px;
      color: black;
      border: solid;
      border-color: #fabe10;
      background-color: #fabe10;
      &:hover {
        color: black;
        background-color: #fabe10;
        text-decoration: underline;
      }
    }
  }
  .selectPubblicazione {
    .MuiSelect-select {
      padding-top: 5px !important;
      padding-bottom: 0 !important;
      height: 22px;
    }

    .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      font-style: italic;
      color: #595959;
      font-size: 16px;
      height: 35px;
      opacity: 1;
    }
    .MuiSelect {
      height: 30px !important;
    }
    margin-bottom: 30px;
    width: 230px;
    border: none;
    height: 30px !important;
    box-shadow: 0px 3px 6px #000000c7;
    opacity: 1;

    .MuiSelect-icon {
      color: black;
    }
  }

  .cardButton {
    border: none;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 3px 6px #000000c7;
    .buttonScaricaCondividiPubblicazione {
      box-shadow: 0px 3px 6px #000000c7;
      margin: 10px;
      width: 250px;
      height: 50px;
      background-color: #f87e31;
      border-radius: 53px;
      color: black;
      &:hover {
        background-color: #f87e31;
      }
      .Mui-disabled {
        color: grey;
      }

      .iconButtonPubblicazione {
        width: 25px;
        margin-top: 5px;
        height: 25px;
      }
      .textButtonPubblicazione {
        font-weight: bold;
        margin-top: -10px;
        text-transform: none;
        font-size: 18px;
      }
    }
  }

  .modaleAttivita {
    opacity: 1;
    position: absolute;
    width: 95%;
    height: 600px;
    @media screen and (max-width: 970px) {
      height: 100%;
    }
    @media screen and (max-width: 360px) {
      height: 90%;
    }
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #707070;
    border-radius: 40px;

    .positionIconCloseModaleAtt {
      @media screen and (max-width: 900px) {
        margin-left: -20px;
        margin-top: -20px;
      }
      @media screen and (max-width: 460px) {
        margin-left: -40px;
        margin-top: -20px;
      }
    }
    .iconCloseModaleAtt {
      color: #000;
      width: 30px;
      height: 30px;
    }
    .buttonModaleAttivita {
      width: 200px;
      height: 50px;
      margin: 20px;
      box-shadow: 0px 3px 6px #000000e0;
      border-radius: 10px;
      color: #000;
      background-color: #fabe10;
      &:hover {
        color: #000;
        background-color: #fabe10;
        text-decoration: underline;
      }
      @media screen and (max-width: 460px) {
        width: 100px;
        height: 30px;
      }
      .text {
        font-weight: bold;
        text-transform: none;
        font-size: 20px;
      }
    }
    .center {
      justify-content: center;
      display: flex;
    }
    .title {
      margin-top: 15px;
      font-size: 22px;
      font-weight: bold;
      @media screen and (max-width: 460px) {
        font-size: 16px;
      }
    }
    .circularProgress {
      z-index: 0;
      color: green;
      margin-right: -40px;
      @media screen and (max-width: 460px) {
        margin-top: 10px;
      }
    }
    .textProgressValue {
      position: relative;
      z-index: 1;
      margin-top: -52px;
      margin-left: 17px;
    }
    .descrizioneModale {
      font-size: 16px;
      margin-top: 30px;
      margin-right: 50px;
    }
    .paper {
      width: 95%;
      overflow: auto;
      max-height: 250px;
      @media screen and (max-width: 970px) {
        max-height: 600px;
      }
      @media screen and (max-width: 780px) {
        max-height: 220px;
      }
      @media screen and (max-width: 460px) {
        max-height: 350px;
      }
      @media screen and (max-width: 360px) {
        max-height: 250px;
      }
    }
    .list {
      max-height: 250px;
      border-radius: 20px;
      font-size: 16px;
      @media screen and (max-width: 970px) {
        max-height: 600px;
      }
      @media screen and (max-width: 460px) {
        max-height: 350px;
      }
    }
  }
}
