.sessionCountdown {
  .MuiTypography-root {
    font-size: 24px;
  }
  .MuiModal-backdrop {
    background-color: $backgroundDialog;
  }
  .MuiDialog-paper {
    width: 50%;
    padding: 25px;
    border-radius: 25px;
    .MuiStack-root {
      .MuiTypography-root {
        text-align: center;
      }
      .secondi {
        font-weight: 600;
        font-size: 30px;
        margin: 10px 0;
      }
      .buttonVerde {
        width: 40%;
        margin: auto;
        margin-top: 25px;
        font-weight: 600;
        svg {
          font-size: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .sessionCountdown {
    .MuiTypography-root {
      font-size: 20px;
    }
    .MuiDialog-paper {
      width: 100%;

      .MuiStack-root {
        .buttonVerde {
          width: 80%;
          font-size: 18px;
        }
      }
    }
  }
}
