.gestisciProgetti {
  .titleColumnTable {
    font-weight: bold;
    font-size: 18px !important;
  }
  .deleteModal {
    opacity: 1;
    position: fixed;
    width: 600px;
    height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #707070;
    border-radius: 40px;
    @media screen and (max-width: 460px) {
      width: 300px;
    }
    .textTitleModalDelete {
      text-align: center;
      font-weight: bold;
      font-size: 26px;
      color: black;
      opacity: 1;
    }
    .fontButtons {
      font-weight: bold;
      text-transform: none;
      font-size: 20px;
    }
    .iconClose {
      float: right;
      color: black;
      margin-right: 20px;
      margin-top: 20px;
    }

    .buttonModaleElimina {
      margin: 20px;
      box-shadow: 0px 3px 6px #000000e0;
      border-radius: 10px;
      color: black;
      width: 200px;
      height: 40px;
      &:hover {
        color: black;
        text-decoration: underline;
      }
      @media screen and (max-width: 460px) {
        width: 100px;
        height: 60px;
      }
    }
  }

  .autocompleteSearch {
    width: 450px;
    margin-bottom: 25px;
    border: none;
    box-shadow: 0px 3px 6px #000000c7;
    opacity: 1;
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiAutocomplete-popupIndicator {
      font-size: small;
      margin-top: -5px;
      color: black;
    }
  }

  .textfieldSearch {
    font-style: italic;
    color: "#595959";
    font-size: 16px;
    opacity: 1;
  }
  .tabs {
    .Mui-selected {
      background-color: #105b70;
      color: #fff;
    }
  }
  .textTab {
    font-size: 20px;
    text-transform: none;
    border-radius: 10px;
    color: black;
    border: 1px solid #105b70;
    margin: 1px;
    @media screen and (max-width: 900px) {
      font-size: 16px;
    }
    @media screen and (max-width: 360px) {
      margin: 0;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .MuiAutocomplete-popupIndicatorOpen {
    transform: rotate(0);
  }

  .MuiTableCell-root {
    padding: 16px 8px;
    .MuiGrid-root {
      line-height: 1;
    }
  }
}
