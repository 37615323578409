.MuiSpeedDial-root {
  display: block !important;
  height: 85px !important;
  width: 85px;
  margin: auto;

  /* ---------- Stile Button apertura/chiusura SpeedDial ---------- */
  .MuiFab-root {
    width: 85px;
    height: 85px;
    margin-right: 12px;
    .MuiStack-root {
      width: 85px;
      height: 85px;
      justify-content: center;
      .MuiAvatar-root {
        background-color: transparent;
        height: fit-content;
        margin: auto;
        margin-top: 25px;
        margin-bottom: -5px;
        font-size: 30px;
        width: 100%;
      }
      svg {
        margin: auto;
      }
    }
  }

  .menuClosed {
    background-color: $verde;
    .MuiStack-root {
      .MuiAvatar-root {
        color: white;
      }
      svg {
        fill: white;
      }
    }
    &:hover {
      background-color: $verde;
    }
  }

  .menuOpened {
    background-color: white;
    .MuiStack-root {
      .MuiAvatar-root {
        color: $verde;
      }
      svg {
        fill: $verde;
      }
    }
    &:hover {
      background-color: white;
    }
  }

  .menuClosed,
  .menuOpened {
    .MuiStack-root {
      .MuiAvatar-root {
        font-weight: 600;
      }
    }
    &:hover {
      .MuiStack-root {
        .MuiAvatar-root {
          text-decoration: underline;
        }
      }
    }
  }
  /* -------- Fine Stile Button apertura/chiusura SpeedDial ------- */

  .MuiSpeedDial-actions {
    .MuiSpeedDialAction-fab {
      margin-left: auto;
      height: 85px;
      width: 85px;
      border: 2px solid $verde;
      .MuiStack-root {
        justify-content: center;
        svg {
          margin: 0 auto;
          color: $verde;
          font-size: 26px;
        }
        .MuiTypography-root {
          text-transform: none;
          color: $verde;
          font-size: 14px !important;
          width: 60px;
          margin: 0 auto;
        }
      }
      &:hover {
        background-color: white;
        .MuiStack-root {
          .MuiTypography-root {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .MuiSpeedDial-root {
    display: none !important;
  }
  .loggedMenuTypography {
    display: none;
  }
}
