.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #277baa;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #2b8427;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: black;
  .Toastify__close-button {
    color: black;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #e41514;
}
