.ricerca {
  .buttonsSezioni {
    border-width: 10px;
    border-color: #8e2155;
    box-shadow: 0px 3px 6px #000000c7;
    background-color: #fff;
    text-align: center;
    color: black;
    width: 500px;
    height: 230px;
    border-radius: 90px;
    opacity: 1;
    @media (max-width: 1100px) {
      width: 300px;
      height: 130px;
    }
    @media (max-width: 360px) {
      width: 270px;
    }
    &:hover {
      color: #fff;
      border-color: #8e2155;
      background-color: #8e2155;
      text-decoration: underline;
    }
    .textButtonsSezioni {
      text-transform: none;
      font-size: 21px !important;
      font-weight: bold;
    }
  }
  .buttonsFiltri {
    margin: 20px;
    box-shadow: 0px 3px 6px #000000e0;
    border-radius: 10px;
    color: #fff;
    background-color: #8e2155;
    width: 174px;
    &:hover {
      color: white;
      background-color: #8e2155;
      text-decoration: underline;
    }
  }
  .visualizzazioneFiltri {
    padding: 10px;
    margin: 15px;
    border: "none";
    background-color: #dea4bd;
    color: black;
    width: 300px;
    border-radius: 20px;
    opacity: 1;
    float: left;
    text-transform: "none";
    font-weight: bold;
    box-shadow: 0px 3px 6px #000000;
    text-decoration: "none";
    @media (max-width: 460px) {
      width: 240px;
    }
    &:hover {
      border: "none";
      background-color: #dea4bd;
    }
    .gridContenutoFiltro {
      cursor: default;
      text-align: left;
      margin-top: 2px;
      margin-left: 5px;
    }
    .textFiltro {
      font-size: 16px !important;
      width: 200px !important;
      word-wrap: break-word;
    }
    .iconFiltro {
      color: #000000;
      width: 20px;
    }
  }
  .buttonIconOpenFilterPage {
    border: "none !important";
    background-color: #8e2155;
    text-align: center;
    color: #000000;
    width: 64px;
    height: 64px;
    border-radius: 90px;
    opacity: 1;
    &:hover {
      border: "none";
      background-color: #8e2155;
    }
  }

  .textButtonIconOpenFilterPage {
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
}
