.gatewayError {
  width: fit-content;
  margin: auto;
  display: grid;
  align-content: center;
  justify-content: center;
  border: 1px solid $verde;
  border-radius: 25px;
  box-shadow: $buttonBoxShadow;
  padding: 40px;
  .icona {
    display: grid;
    align-content: center;
    justify-content: center;
    margin-bottom: 50px;
    svg {
      height: 250px;
      margin: auto;
      margin-bottom: 25px;
    }
  }
  .MuiTypography-root {
    text-align: center;
    font-size: 24px;
  }
}
