.strumenti {
  padding: 24px;
  .MuiButton-root {
    color: $verde;
    text-transform: none;
    font-size: 24px;
    margin-top: 25px;
    font-weight: 600;
    padding-left: 0;
    svg {
      font-size: 32px;
      rotate: 45deg;
    }
    &:hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }
  strong {
    padding: 0 5px;
  }
}

@media screen and (max-width: 899px) {
  .strumenti {
    padding: 24px 12px;
    .MuiButton-root {
      font-size: 16px;
      svg {
        font-size: 24px;
      }
    }
  }
}
