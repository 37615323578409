@import "./profilo/modalProfilo";

.profilo {
  padding: 24px;
  padding-top: 0;
  form {
    padding-top: 24px;
  }
  /* ----------- Dati multimediali ----------- */
  .datiMultimedia {
    margin-top: 25px;
    .MuiAvatar-circular {
      width: 200px;
      height: 200px;
      border: 2px solid $verde;
      background-color: white;
      svg {
        color: $verde;
      }
    }
    .nomeCognome {
      font-weight: 600;
      font-size: 22px;
    }
    .nomeAvatar {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      svg {
        font-size: 24px;
        height: 24px;
      }
      .MuiTypography-root {
        margin-left: 10px;
        font-size: 14px;
      }
      .MuiIconButton-root {
        margin-top: -5px;
        margin-left: 25px;
        svg {
          height: 20px;
          width: 20px;
        }
        &:hover {
          background-color: black;
          svg {
            fill: white;
          }
        }
      }
    }
    .uploadAvatarStack {
      .uploadAvatar {
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          color: black;
          font-size: 28px;
          margin-right: 10px;
        }
        .MuiTypography-root {
          color: black;
        }
        &:hover {
          .MuiTypography-root {
            text-decoration: underline;
            font-weight: 600;
          }
        }
      }
      .dimensioneFile {
        font-size: 14px !important;
        font-style: oblique;
      }
    }
    .biografia {
      label {
        opacity: 0;
      }
      .MuiFormControl-root {
        label {
          opacity: 1;
        }
      }
      .MuiFormHelperText-root {
        text-align: right;
      }
    }
  }

  /* --------- Fine dati multimediali -------- */

  .datiPersonali,
  .datiIstituto {
    padding: 16px;
    .headerSezione {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 16px;
      svg {
        font-size: 26px;
        margin-right: 15px;
      }
      .MuiTypography-root {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  /* ------------ Dati personali ------------- */
  .datiPersonali {
    a {
      display: block;
      width: fit-content;
      text-decoration: none;
      .MuiTypography-root {
        color: $verde;
      }
      &:hover {
        .MuiTypography-root {
          text-decoration: underline;
        }
      }
    }
    .MuiCheckbox-root.Mui-checked {
      color: $verde;
    }
    .MuiDivider-root {
      margin-top: 25px;
      border-bottom-width: 3px;
      border-color: $verde;
    }
  }
  /* ---------- Fine dati personali ---------- */

  /* ------------ Dati istituto ------------- */
  .datiIstituto {
    .incaricoProvvisorio {
      font-style: oblique;
      color: #5c5c5c;
      font-size: 14px !important;
      line-height: 1;
    }
    .buttonVerdeChiaro {
      padding: 1px 16px !important;
      margin: auto 0 !important;
    }
  }
  /* ---------- Fine dati istituto ---------- */

  .disabilitaButton {
    padding: 16px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .MuiStack-root {
      display: grid;
      .MuiIconButton-root {
        width: 46px;
        height: 46px;
        box-shadow: $buttonBoxShadow;
        margin: auto;
        margin-bottom: 5px;
        svg {
          color: black;
        }
        &:hover {
          background-color: transparent;
        }
      }
      .MuiTypography-root {
        font-size: 14px !important;
      }
      &:hover {
        .MuiTypography-root {
          text-decoration: underline;
        }
      }
    }
  }
  .footerButtons {
    display: flex;
    justify-content: space-around;
    .buttonVerde,
    .buttonVerdeSecondario {
      margin: auto 0 !important;
      width: 210px;
    }
  }
}

@media screen and (max-width: 900px) {
  .profilo {
    padding: 12px 0;
    .datiPersonali,
    .datiIstituto {
      padding: 16px 0;
    }
  }
}
