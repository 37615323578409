.home {
  position: relative;
  height: 100%;
  .buttonGestioneProgetti {
    box-shadow:
      0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14),
      0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    background-color: $verde;
    border: 1px solid $verde;
    width: 85px;
    height: 85px;
    border-radius: 90px;
    svg {
      fill: white;
      width: 45px;
      height: 45px;
    }
    &:hover {
      background-color: white;
      svg {
        fill: $verde;
      }
    }
  }

  .buttonInserisciProgetto {
    .MuiCard-root {
      width: 550px;
      .MuiCardHeader-root {
        border-color: $giallo;
        background-color: $giallo;
      }
      .MuiCardContent-root {
        border-color: $giallo;
        position: relative;
        background: transparent linear-gradient(0deg, #ffffff 0%, #fff9e9 30%, #fff7e2 54%, #fff6dc 78%, #fff4d5 100%) 0% 0% no-repeat padding-box;
        .MuiStack-root {
          width: 120px;
          height: 120px;
          background-color: $giallo;
          border-radius: 100%;
          position: absolute;
          top: -20px;
          left: calc(calc(100% - 120px) / 2);
          svg {
            margin: auto;
            width: 60%;
          }
        }
      }
    }
  }

  .buttonMappa,
  .buttonRicerca,
  .buttonDatiSintesi {
    .MuiCard-root {
      width: 350px;
      .MuiCardHeader-root {
        border-color: $vinaccia;
        background-color: $vinaccia;
        .MuiTypography-root {
          color: white;
        }
      }
      .MuiCardContent-root {
        border-color: $vinaccia;
        background: transparent linear-gradient(0deg, #ffffff 0%, #fbf3f7 30%, #f6e4ec 64%, #f1dde6 86%, #ebd6e0 100%) 0% 0%;
        svg {
          height: 80px;
          fill: $vinaccia;
        }
      }
    }
  }

  .buttonInserisciProgetto,
  .buttonMappa,
  .buttonRicerca,
  .buttonDatiSintesi {
    padding: 0;
    .MuiCard-root {
      .MuiCardHeader-root {
        border-width: 10px;
        border-style: solid;
        border-bottom: 0;
        padding: 16px;
        padding-top: 8px;
        .MuiTypography-root {
          font-size: 24px !important;
          font-weight: 600;
          text-transform: none;
        }
      }
      .MuiCardContent-root {
        border-width: 10px;
        border-style: solid;
        border-top: 0;
        height: 80px;
      }
    }
    &:hover {
      box-shadow: 0px 3px 6px #00000099;
      .MuiCard-root {
        .MuiCardHeader-root {
          .MuiTypography-root {
            text-decoration: underline;
          }
        }
        .MuiCardContent-root {
          border-color: white;
          background: white;
        }
      }
    }
  }

  // Posizionamento assoluto versione desktop
  .loghiPersonali,
  .inserimento,
  .mappaCercaDati,
  .loghiCoesione {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .loghiPersonali {
    top: 5px;
    height: 15%;

    .logoScuola {
      svg {
        margin: 25px 20px;
        height: 80px;
        @media screen and (max-width: 900px) {
          max-width: 100px;
        }
        @media screen and (max-width: 600px) {
          max-width: 200px;
        }
        @media screen and (max-width: 460px) {
          max-width: 140px;
        }
      }
      h1 {
        position: absolute;
        top: 0;
        left: -999999999px;
      }
    }
  }

  .inserimento {
    top: calc(15% + 5px);
    height: calc(35% - 5px);
  }

  .mappaCercaDati {
    top: 50%;
    height: 35%;
  }

  .loghiCoesione {
    top: 85%;
    height: 10%;
    text-align: center;
    img {
      max-width: 40%;
      max-height: 80px;
    }
  }
}

.modaleInserimento {
  .MuiDialog-paper {
    max-width: 100%;
    width: 1000px;
    height: 600px;
    border-radius: 40px;
    .MuiDialogTitle-root {
      display: flex;
      justify-content: flex-end;
      padding: 32px;
      h2 {
        display: none;
      }
      .MuiIconButton-root {
        padding: 0;
        svg {
          height: 36px;
          width: 36px;
          fill: black;
        }
        &:hover {
          background-color: black;
          svg {
            fill: white;
          }
        }
      }
    }
    .MuiDialogContent-root {
      .modalHeaderTitle {
        padding-top: 25px;
        padding-bottom: 75px;
        text-align: center;
        font-weight: 600;
        font-size: 32px;
      }
      .MuiGrid-grid-xs-12 {
        display: flex;
        justify-content: center;
        .MuiButton-root {
          padding: 0 25px;
          width: 80%;
          height: 250px;
          box-shadow: 0px 3px 6px #000000a3;
          border-radius: 40px;
          background-color: $giallo;
          color: black;
          font-weight: 600;
          font-size: 20px;
          text-transform: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .home {
    .buttonGestioneProgetti {
      width: 70px;
      height: 70px;
      svg {
        width: 40px;
        height: 40px;
      }
    }

    .buttonInserisciProgetto {
      margin-top: 20px;
      .MuiCard-root {
        width: 350px;
        .MuiCardContent-root {
          height: 50px;
          .MuiStack-root {
            width: 90px;
            height: 90px;
            top: -10px;
            left: calc(calc(100% - 90px) / 2);
          }
        }
      }
    }

    .buttonMappa,
    .buttonRicerca,
    .buttonDatiSintesi {
      .MuiCard-root {
        .MuiCardContent-root {
          border-width: 0;
        }
      }
    }

    .buttonInserisciProgetto,
    .buttonMappa,
    .buttonRicerca,
    .buttonDatiSintesi {
      .MuiCard-root {
        border-radius: 10px;
        .MuiCardHeader-root {
          padding: 8px;
          .MuiTypography-root {
            font-size: 18px !important;
          }
        }
      }
    }

    // Posizionamento relativo versione mobile
    .loghiPersonali,
    .inserimento,
    .mappaCercaDati,
    .loghiCoesione {
      position: relative;
      height: auto;
      top: auto;
    }

    .mappaCercaDati {
      margin-top: 25px;
    }

    .loghiPersonali {
      .logoScuola {
        display: flex;
        flex-direction: row;
        width: 100%;
        svg {
          height: 75px;
          width: 50%;
        }
      }
    }

    .loghiCoesione {
      margin-top: 25px;
      img {
        max-width: 100%;
      }
    }
  }

  .modaleInserimento {
    .MuiDialog-paper {
      width: 100%;
      height: 650px;
      border-radius: 40px;
      .MuiDialogTitle-root {
        padding: 24px;
        padding-bottom: 0;
      }
      .MuiDialogContent-root {
        .modalHeaderTitle {
          padding-top: 10px;
          padding-bottom: 30px;
          font-size: 24px;
          @media screen and (max-width: 460px) {
            margin-top: 0px;
            font-size: 14px;
          }
        }
        .MuiGrid-grid-xs-12 {
          .MuiButton-root {
            width: 100%;
            height: 175px;
            padding: 0 25px;
            margin-bottom: 30px;
            font-size: 18px;
            @media screen and (max-width: 460px) {
              font-size: 14px;
              height: 145px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .home {
    .buttonInserisciProgetto {
      .MuiCard-root {
        width: 350px;
        .MuiCardContent-root {
          height: 60px;
          .MuiStack-root {
            width: 100px;
            height: 100px;
            top: -10px;
            left: calc(calc(100% - 100px) / 2);
          }
        }
      }
    }

    .buttonMappa,
    .buttonRicerca,
    .buttonDatiSintesi {
      .MuiCard-root {
        .MuiCardContent-root {
          border-width: 0;
        }
      }
    }

    .buttonInserisciProgetto,
    .buttonMappa,
    .buttonRicerca,
    .buttonDatiSintesi {
      .MuiCard-root {
        border-radius: 10px;
        .MuiCardHeader-root {
          padding: 8px;
          margin-top: -8px;
          .MuiTypography-root {
            font-size: 18px !important;
          }
        }
      }
    }

    // Posizionamento relativo versione mobile
    .loghiPersonali,
    .inserimento,
    .mappaCercaDati,
    .loghiCoesione {
      position: relative;
      height: auto;
      top: auto;
    }

    .mappaCercaDati {
      margin-top: 25px;
    }

    .loghiCoesione {
      margin-top: 25px;
      img {
        max-width: 100%;
      }
    }
  }
}

@media screen and (min-height: 933px) {
  .home {
    .loghiPersonali {
      height: 15%;
    }
    .inserimento {
      height: 20%;
      position: absolute;
      top: 15%;
    }
    .mappaCercaDati {
      position: absolute;
      top: 32%;
    }
    .loghiCoesione {
      position: absolute;
      bottom: 5%;
    }
  }
}
