.empty {
  display: grid;
  align-content: center;
  justify-content: center;
  height: 100%;
  svg {
    height: 200px;
    margin-bottom: 15px;
  }
  .MuiTypography-root {
    text-align: center;
  }
}
