.mobileLoggedMenu,
.navigationModal {
  display: none;
}

@media screen and (max-width: 900px) {
  .mobileLoggedMenu {
    display: grid;

    .MuiStack-root {
      .MuiAvatar-root {
        &:hover {
          text-decoration: underline !important;
          cursor: pointer;
        }
        background-color: $verde;
        margin: 0 auto;
        font-size: 30px;
        font-weight: 600;
        width: 70px;
        height: 70px;
        box-shadow:
          0px 3px 5px -1px rgba(0, 0, 0, 0.2),
          0px 6px 10px 0px rgba(0, 0, 0, 0.14),
          0px 1px 18px 0px rgba(0, 0, 0, 0.12);
      }
    }
    .MuiTypography-root {
      margin-top: 1px;
      margin-bottom: -1px;
      letter-spacing: normal;
      &:hover {
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
  }

  .navigationModal {
    display: block;
    .MuiDialog-paper {
      width: 80%;
      border-radius: 25px;
      .MuiDialogTitle-root {
        display: flex;
        justify-content: flex-end;
        padding-right: 12px;
        h2 {
          display: none;
        }
        .MuiIconButton-root {
          svg {
            fill: black;
          }
          &:hover {
            background-color: black;
            svg {
              fill: white;
            }
          }
        }
      }
      .MuiDialogContent-root {
        display: grid;
        padding: 0;
        padding-bottom: 10px;
        .MuiButton-root {
          width: 75%;
          border: 3px solid $verde;
          border-radius: 10px;
          margin: auto;
          margin-bottom: 30px;
          justify-content: space-between;
          box-shadow: $buttonBoxShadow;
          padding: 16px 24px;
          svg {
            color: $verde;
            font-size: 36px;
          }
          .MuiTypography-root {
            width: 100%;
            text-transform: none;
            text-align: center;
            color: black;
            font-size: 20px;
          }
        }
      }
    }
  }
}
